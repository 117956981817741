import { configureStore, ThunkAction, Action, combineReducers } from "@reduxjs/toolkit";
import * as Reports from "./reports"
import * as Builder from "./builder"
import * as SearchNotes from "./search-notes"


const reports = combineReducers({
    filters: Reports.filters,
    table: Reports.table,
    notes: Reports.notes
})

const builder = combineReducers({
    query: Builder.query,
    table: Builder.table
})

const searchNotes = combineReducers({
    table: SearchNotes.table
})

export const store = configureStore({
    reducer: {
        reports,
        builder,
        searchNotes,
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
