import { initializeApp } from "firebase/app";
// import { connectFunctionsEmulator, getFunctions } from "firebase/functions";


const config = {
	apiKey: process.env.REACT_APP_APIKEY,
	authDomain: process.env.REACT_APP_AUTHDOMAIN,
	projectId: process.env.REACT_APP_PID,
	storageBucket: process.env.REACT_APP_SB,
	messagingSenderId: process.env.REACT_APP_SID,
	appId: process.env.REACT_APP_APPID,
};

initializeApp(config);

if (process.env.NODE_ENV === "development") {
	// connectFunctionsEmulator(
	// 	getFunctions(),
	// 	"localhost",
	// 	5001
	// );
	// firebase.auth().useEmulator("http://localhost:9099");
	// firebase.firestore().useEmulator("localhost", 8080);
	// firebase.functions().useEmulator("localhost", 5001);
	// firebase.storage().useEmulator("localhost", 9199);
}