import { FC } from "react"
import { Outlet } from "react-router-dom"
import { Container, Header, Navbar, Content, FlexboxGrid, Footer } from 'rsuite'
import logo from "assets/images/logo.png"
const Auth: FC = () => {
    return (
        <Container style={{ height: "100vh" }}>
            <Header>
                <Navbar appearance="inverse">
                    <Navbar.Brand>

                    </Navbar.Brand>
                </Navbar>
            </Header>
            <Content>
                <FlexboxGrid justify="center">
                    <FlexboxGrid.Item colspan={24}>
                        <Outlet />
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
            <Footer style={{ textAlign: "end" }}>
                <img src={logo} alt="logo" style={{ width: "10rem", opacity: "0.5", margin: "1rem" }} />
            </Footer>
        </Container>
    )
}

export default Auth
