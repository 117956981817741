import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit"
import { AxiosResponse } from "axios";
import { Well, PayloadInsertNote, PayloadUpdateNote, Response, ResponseCreateNote, RequestCreateNote, ResponseGetNoteByWell, NoteApi, PayloadUpdateStatusNote, NoteStatus } from "types";
import { axios } from "utils/axios";

const name = "reports/notes"

export type NotesState = {
    data: ResponseGetNoteByWell[];
    isLoading: boolean;
    errors: SerializedError[];
    isModalDeleteOpen: boolean;
    isModalUpdateOpen: boolean;
    isModalAddOpen: boolean;
    isPendingNote: boolean;
    isModalResolveOpen: boolean;
    note: NoteApi | null;
}

export enum Actions {
    FETCH_NOTES = "reports/notest/fetchNotes/pending",
    INSERT_NOTE = "reports/notest/insertNote/pending",
    UPDATE_NOTE = "reports/notest/updateNote/pending",
    DELETE_NOTE = "reports/notest/deleteNote/pending"
}

const initialState: NotesState = {
    data: [],
    isLoading: false,
    isModalDeleteOpen: false,
    isModalUpdateOpen: false,
    isModalAddOpen: false,
    isPendingNote: false,
    isModalResolveOpen: false,
    note: null,
    errors: []
}

export const fetchNotes = createAsyncThunk<
    ResponseGetNoteByWell[],
    Well,
    {
        rejectValue: Response<ResponseGetNoteByWell[]>
    }
>(
    Actions.FETCH_NOTES,
    async (well: Well, thunkApi) => {
        try {
            const response = (await axios.get<Response<ResponseGetNoteByWell[]>>(`/notes/${well.external_id}`))
            return response.data.payload!
        } catch (error: any) {
            return thunkApi.rejectWithValue(error.response?.data)
        }
    }
)

export const insertNote = createAsyncThunk(
    Actions.INSERT_NOTE,
    async (payload: PayloadInsertNote) => {
        const payloadRequest: RequestCreateNote = {
            description: payload.note,
            wellId: payload.well.id,
            user: payload.user.email!.split("@")[0],
            status: payload.status,
            attachments: payload.files,
            assignTo: payload.assignTo,
            deadline: payload.deadline
        }
        return (await axios.post<any, AxiosResponse<Response<ResponseCreateNote>>, RequestCreateNote>("/notes", payloadRequest)).data.payload
    },
    {}
)


export const updateNote = createAsyncThunk(
    Actions.UPDATE_NOTE,
    async (payload: PayloadUpdateNote) => {
        const payloadRequest: RequestCreateNote = {
            description: payload.note,
            wellId: payload.well.id,
            user: payload.user.email!.split("@")[0],
            attachments: payload.files,
            status: payload.status,
            assignTo: payload.assignTo,
            deadline: payload.deadline
        }
        return (await axios.put<any, AxiosResponse<Response<ResponseCreateNote>>, RequestCreateNote>(`/notes/${payload.id}`, payloadRequest)).data
    }
)

export const updataStatusNote = createAsyncThunk(
    Actions.UPDATE_NOTE,
    async (payload: PayloadUpdateStatusNote) => {
        const payloadRequest: any = {
            status: NoteStatus.RESOLVED
        }
        return (await axios.put<any, AxiosResponse<Response<ResponseCreateNote>>,
            RequestCreateNote>(`/notes/status/${payload.id}`, payloadRequest)).data
    }
)


export const deleteNote = createAsyncThunk(
    Actions.DELETE_NOTE,
    async (payload: number) => {
        return (await axios.delete<any, AxiosResponse<Response<ResponseCreateNote>>>(`/notes/${payload}`)).data
    }
)

export const notesSlice = createSlice({
    name,
    initialState,
    reducers: {
        reset: () => {
            return {
                ...initialState
            };
        },
        setModalDelete: (state: NotesState, action: PayloadAction<boolean>) => {
            state.isModalDeleteOpen = action.payload;
        },
        setModalUpdate: (state: NotesState, action: PayloadAction<boolean>) => {
            state.isModalUpdateOpen = action.payload;
        },
        setModalAdd: (state: NotesState, action: PayloadAction<boolean>) => {
            state.isModalAddOpen = action.payload;
        },
        sendPendingNote: (state: NotesState, action: PayloadAction<boolean>) => {
            state.isPendingNote = action.payload;

        },
        setModalResolve: (state: NotesState, action: PayloadAction<boolean>) => {
            state.isModalResolveOpen = action.payload;
        },
        setNote: (state: NotesState, action: PayloadAction<NoteApi>) => {
            state.note = action.payload;
        },
        clearNotes: (state: NotesState) => {
            state.data = initialState.data;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotes.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchNotes.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchNotes.rejected, (state, action) => {
                state.isLoading = false
                state.errors = [...state.errors, {
                    ...action.payload!.error,
                }]
            })
    }
})


export const { reset, setModalAdd, setModalUpdate, setModalDelete, setModalResolve, setNote, sendPendingNote, clearNotes } = notesSlice.actions

export default notesSlice.reducer