import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import _ from "lodash";
import { FiltersApi, FiltersApiRequest, Response } from "types";
import { axios } from "utils/axios";

export enum Actions {
    FETCH_FILTER = "reports/filters/fetchFilter/pending"
}

export type FiltersState = {
    data: Record<string, FiltersApi>,
    isLoading: boolean,
    onClose: boolean,
    errors: Array<SerializedError & { name: string }>,
    expanded: boolean,
    conditions: string[]
}

export type PayloadValues = {
    key: string,
    values: string[]
}

export type PayloadClear = {
    headers: string[]
}

const name = "reports/filters"

const initialState: FiltersState = {
    data: {},
    isLoading: false,
    onClose: false,
    errors: [],
    expanded: false,
    conditions: []
};

export const fetchFilter = createAsyncThunk(
    Actions.FETCH_FILTER,
    async ({ id, column, isData = false, fields = [], isFieldData = [], values = [], user, uid, contributedByMe, isAssignToMe }: FiltersApiRequest) => {
        const response = (await axios.get<Response<FiltersApi>>(`/wells/filters/${id}/${column}?isData=${isData}&fields=${_.join(fields)}&values=${_.join(values)}&isFieldData=${_.join(isFieldData)}&user=${user}&uid=${uid}&contributedByMe=${contributedByMe}&isAssignToMe=${isAssignToMe}`)).data
        return response.payload!
    },
)

export const filtersSlice = createSlice({
    name,
    initialState,
    reducers: {
        // Reset store to initial state
        reset: (state: FiltersState) => {
            return {
                ...initialState, expanded: state.expanded
            }
        },
        // Set selected values of an input filter
        setValues: (state: FiltersState, action: PayloadAction<PayloadValues>) => {
            state.data[action.payload.key].values = action.payload.values
        },
        setExpanded: (state: FiltersState, action: PayloadAction<boolean>) => {
            state.expanded = action.payload
        },
        setConditions: (state: FiltersState, action: PayloadAction<string[]>) => {
            state.conditions = action.payload
        },
        setOnClose: (state: FiltersState, action: PayloadAction<boolean>) => {
            state.onClose = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFilter.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(fetchFilter.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = { ...state.data, [action.payload.column]: action.payload }
            })
            .addCase(fetchFilter.rejected, (state, action) => {
                state.isLoading = false
                state.errors = [...state.errors, {
                    ...action.error,
                    name: action.meta.arg.name
                }]
            })
    }
})

export const { reset, setValues, setExpanded, setConditions, setOnClose } = filtersSlice.actions

export default filtersSlice.reducer