import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes.router";

const Router: React.FC = () => {
	return (
		<BrowserRouter>
			<Routes />
		</BrowserRouter>
	);
};

export default Router;
