
import { getAuth, signInWithEmailAndPassword, User, signOut, OAuthProvider, RecaptchaVerifier, signInWithPhoneNumber, onIdTokenChanged } from "firebase/auth";
import firebaseui from "firebaseui";
import { createContext, FC, useState, useEffect } from "react";
import { AuthContext as IAuthContext, Login } from "types"
import { axios } from "utils/axios";

export const AuthContext = createContext<IAuthContext>({
    user: null,
    isLoggedIn: false,
    loading: false,
    token: null
})

const AuthProvider: FC = (props) => {
    const [user, setUser] = useState<null | User>(null)
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [token, setToken] = useState<string | null>(null)

    useEffect(() => {
        setLoading(true)
        const onTokenStateChange = () => onIdTokenChanged(getAuth(), async (user) => {
            const idToken = await user?.getIdToken() || ""
            setUser(user);
            setIsLoggedIn(!!user);
            setToken(idToken);
            axios.defaults.headers.common = { 'Authorization': `Bearer ${idToken!}` };
            setLoading(false);
        });
        const unsubscribe = onTokenStateChange()
        return () => {
            unsubscribe()
        }
    }, [])

    const login = async ({ email, password }: Login) => {
        const { user } = await signInWithEmailAndPassword(getAuth(), email, password)
        setUser(user)
        setIsLoggedIn(!!user)
    }

    const logout = async () => {
        await signOut(getAuth())
    }

    const setUpRecaptcha = (number: string) => {
        const recaptchaVerifier = new RecaptchaVerifier(
            'recaptcha-container',
            { 'size': 'invisible', },
            getAuth()
        );
        recaptchaVerifier.render()
        return signInWithPhoneNumber(getAuth(), number, recaptchaVerifier)
    }

    const handleMsLogin = () => {
        const provider = new OAuthProvider('microsoft.com')
        const uiConfig: firebaseui.auth.Config = {
            signInFlow: "popup",
            signInOptions: [
                {
                    provider: provider.providerId,
                    customParameters: {
                        tenant: process.env.REACT_APP_TENANT!,
                        prompt: 'select_account'
                    },
                    // scopes: [
                    //     'mail.read'
                    // ]
                }
            ],
            callbacks: {
                signInSuccessWithAuthResult: ((authResult, redirectUrl) => { return false })
            }
        }
        return uiConfig
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                isLoggedIn,
                loading,
                token,
                login,
                logout,
                setLoading,
                setUpRecaptcha,
                handleMsLogin,
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthProvider