import { FC } from 'react'
import logo from "assets/images/logo_blue.png"
const Loading: FC = () => {
    return (
        <div className="backdrop">
            <img src={logo} alt="" className="image-loading" />
        </div>
    )
}

export default Loading
