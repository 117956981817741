import { FC } from 'react'
import { Grid, FlexboxGrid } from 'rsuite'
import logo from "assets/images/logo.png"
const InvalidVersions: FC = () => {
    return (
        <Grid style={{ height: "100vh" }}>
            <FlexboxGrid style={{ height: "100%" }} align="middle">

                <FlexboxGrid.Item colspan={24} style={{ textAlign: "center" }} >
                    <h1 style={{ margin: "2rem", textAlign: "center", fontSize: "100px" }}>Hello!</h1>
                    <h1 style={{ margin: "2rem", textAlign: "center" }}>There is a new version of this application</h1>
                    <p style={{ margin: "2rem", textAlign: "center", fontSize: "20px" }}>Please try clearing your cache and reloading the app.</p>
                    <img src={logo} style={{ margin: "2rem" }} alt="logo" />
                </FlexboxGrid.Item>

            </FlexboxGrid>
        </Grid>
    )
}

export default InvalidVersions
