import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit"
import { AxiosResponse } from "axios";
import { ReportResponse, ReportApi, PayloadSaveQuery, PickerOptions, Response } from "types";
import { axios } from "utils/axios";

const name = "builder/queries"

export type QueryState = {
    data: ReportResponse;
    isLoading: boolean;
    errors: SerializedError[];
    isModalDeleteOpen: boolean;
    isModalOpen: boolean;
    query: string;
    name: string;
    id: string | undefined;
    edit: boolean;
    report: ReportApi | null;
    reports: PickerOptions[];
}

export enum Actions {
    FETCH_QUERIES = "builder/query/fetchReports/pending",
    SAVE_QUERY = "builder/query/saveQuery/pending",
    UPDATE_QUERY = "builder/query/updateQuery/pending",
    DELETE_QUERY = "builder/query/deleteQuery/pending"
}

const initialState: QueryState = {
    data: {
        results: [],
        total: 0,
    },
    isLoading: false,
    isModalDeleteOpen: false,
    isModalOpen: false,
    query: "",
    name: "",
    errors: [],
    id: undefined,
    edit: false,
    report: null,
    reports: []
}

export const fetchReports = createAsyncThunk(
    Actions.FETCH_QUERIES,
    async () => {
        const response = await axios.get<Response<ReportApi[]>, AxiosResponse<Response<ReportApi[]>>>("/reports")
        const options: PickerOptions[] = response.data.payload!.map(report => {
            return {
                label: report.name,
                value: JSON.stringify(report)
            }
        })
        return options
    }
)

export const saveQuery = createAsyncThunk(
    Actions.SAVE_QUERY,
    async (payload: PayloadSaveQuery) => {
        return (await axios.post<Response<ReportApi>, AxiosResponse<Response<ReportApi>>, ReportApi>("/reports", payload)).data
    }
)

export const deleteQuery = createAsyncThunk(
    Actions.DELETE_QUERY,
    async (id: number) => {
        return (await axios.delete<Response<ReportApi>>(`/reports/${id}`)).data
    }
)

export const querySlice = createSlice({
    name,
    initialState,
    reducers: {
        reset: () => {
            return {
                ...initialState
            }
        },
        setModalDelete: (state: QueryState, action: PayloadAction<boolean>) => {
            state.isModalDeleteOpen = action.payload
        },
        setModal: (state: QueryState, action: PayloadAction<boolean>) => {
            state.isModalOpen = action.payload;
        },
        setQuery: (state: QueryState, action: PayloadAction<string>) => {
            state.query = action.payload
        },
        setSelect: (state: QueryState, action: PayloadAction<{ name: string, query: string, report: ReportApi }>) => {
            const { name, query, report } = action.payload;
            state.name = name;
            state.query = query;
            state.report = report;
            state.edit = true;
        },
        setClose: (state: QueryState) => {
            return {
                ...initialState,
                reports: state.reports
            }
        },
        setReports: (state: QueryState, action: PayloadAction<PickerOptions[]>) => { state.reports = action.payload }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchReports.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(fetchReports.fulfilled, (state, action) => {
                if (action.payload.length) {
                    state.reports = action.payload
                }
                state.isLoading = false
            })
            .addCase(fetchReports.rejected, (state, action) => {
                state.isLoading = false

                state.errors = [...state.errors, {
                    ...action.error,
                }]
            })
            .addCase(saveQuery.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(saveQuery.fulfilled, (state) => {
                state.isLoading = false
            })
            .addCase(saveQuery.rejected, (state, action) => {
                state.isLoading = false
                state.errors = [...state.errors, {
                    ...action.error,
                }]
            })
    }
})


export const { reset, setModal, setModalDelete, setQuery, setSelect, setClose, setReports } = querySlice.actions

export default querySlice.reducer