import { createContext, FC, useRef, useState } from "react";
import { LoadingContext as ILoadingContext } from "types"

export const LoadingContext = createContext<ILoadingContext>({
    loading: false
})

const LoadingProvider: FC = (props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const ref = useRef<HTMLAnchorElement>(null)!;

    const startLoading = (fn: Function) => {
        setLoading(true)
        fn()
    }

    return (
        <LoadingContext.Provider
            value={{
                loading,
                setLoading,
                startLoading,
            }}
        >
            <a style={{ display: 'none' }} href="/" ref={ref}>DOWLOAD REFERENCE</a>
            {props.children}
        </LoadingContext.Provider>
    )
}

export default LoadingProvider