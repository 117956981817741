import { FC, useContext, useState } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import {
	Container,
	Header,
	Nav,
	Content,
	Sidebar,
	Sidenav,
	IconButton,
	Footer,
} from "rsuite";
import { Dashboard, Exit, Code, Search } from "@rsuite/icons";
import { context } from "providers";
import logo from "assets/images/logo.png"
import logo_white from "assets/images/logo_white.png"

const App: FC = () => {
	const { logout } = useContext(context.auth)
	const navigate = useNavigate()
	const location = useLocation()
	const [activeKey, setActiveKey] = useState(location.pathname);


	return (
		<Container>
			<Sidebar
				style={{ display: "flex", flexDirection: "column", backgroundColor: "#3366CC", width: "7vw", height: "100vh", maxHeight: "100vh" }}
				width={0}
			>
				<Sidenav appearance="inverse" expanded={false} defaultOpenKeys={['1']}>
					<Sidenav.Header>
						<div
							style={{
								padding: 18,
								fontSize: 16,
								height: 56,
								background: "#3366CC",
								color: " #fff",
								whiteSpace: "nowrap",
								overflow: "hidden",
							}}
						>
							<img src={logo_white} alt="logo" style={{ width: "20px" }} />
						</div>
					</Sidenav.Header>
					<Sidenav.Body>
						<Nav activeKey={activeKey} onSelect={(eventKey: any) => {
							setActiveKey(eventKey)
							navigate(eventKey)
						}}
							appearance="default" >
							<Nav.Item eventKey="/" icon={<Dashboard />}>
								Reports
							</Nav.Item>
							<Nav.Item eventKey="/builder" icon={<Code />}>
								Query builder
							</Nav.Item>
							<Nav.Item eventKey="/search-notes" icon={<Search />}>
								Search notes
							</Nav.Item>
						</Nav>
					</Sidenav.Body>
				</Sidenav>
			</Sidebar>

			<Container style={{ width: "95vw" }}>
				<Header style={{ padding: "1.0rem", height: "8vh" }}>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<h3>Well Notes</h3>
						<IconButton onClick={async () => {
							await logout!()
							navigate("/")
						}} icon={<Exit />} appearance="primary" />
					</div>
				</Header>
				<Content style={{ display: "flex", maxHeight: "92vh" }}>
					<Outlet />
				</Content>
				<Footer style={{ zIndex: "-1", textAlign: "end", position: "absolute", display: "flex", justifyContent: "end", alignItems: "end", width: "95vw", height: "100vh" }}>
					<img src={logo} alt="logo" style={{ width: "10rem", height: "2.5rem", opacity: "0.5", margin: "1rem" }} />
				</Footer>
			</Container>
		</Container>
	);
};

export default App;
