import InvalidVersions from 'pages/invalid-versions';
import { FC } from 'react'
import { Route, Routes } from 'react-router-dom';

type Version = {
    app: string;
    server: string;
}

const VersionRouter: FC<Version> = ({ app, server }) => {

    return (
        <Routes>
            <Route path="*" element={<InvalidVersions />} />
        </Routes>
    )
}

export default VersionRouter