import { FC } from "react"
import AuthProvider, { AuthContext } from "./auth.provider"
import LoadingProvider, { LoadingContext } from "./loading.provider"
import { store } from "store";
import { Provider as ReduxProvider } from "react-redux";

export const context = {
    auth: AuthContext,
    loading: LoadingContext,
}

const Providers: FC = (props) => {
    console.log(process.env.REACT_APP_VERSION);
    return (
        <ReduxProvider store={store}>
            <LoadingProvider>
                <AuthProvider>
                    {props.children}
                </AuthProvider>
            </LoadingProvider>
        </ReduxProvider>
    )
}

export default Providers