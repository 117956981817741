import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Grid, FlexboxGrid, Button } from 'rsuite'
import logo from "assets/images/logo.png"
const NotFound: FC = () => {
    return (
        <Grid style={{ height: "100vh" }}>
            <FlexboxGrid style={{ height: "100%" }} align="middle">

                <FlexboxGrid.Item colspan={24} style={{ textAlign: "center" }} >
                    <h1 style={{ margin: "2rem", textAlign: "center", fontSize: "100px" }}>404</h1>
                    <h1 style={{ margin: "2rem", textAlign: "center" }}>Not Found</h1>
                    <p style={{ margin: "2rem", textAlign: "center", fontSize: "20px" }}>This resource requested could not be found.</p>
                    <Button as={Link} appearance="link" to="/" block>Go to home</Button>
                    <img src={logo} style={{ margin: "2rem" }} alt="logo" />
                </FlexboxGrid.Item>

            </FlexboxGrid>
        </Grid>
    )
}

export default NotFound
