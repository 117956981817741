import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import _ from "lodash";
import { Sort, Well, PickerOptions, ReportApi, Response, WellApi, ReportApiRequest, Header } from "types";
import { axios } from "utils/axios";

const name = "reports/table"

export type TableState = {
    data: WellApi
    isAssignToMe: boolean
    contributedByMe: boolean
    isLoading: boolean
    headers: Header[]
    nonDynamicHeaders: string[]
    columns: string[]
    columnInitial: boolean
    errors: SerializedError[]
    count: number
    page: number
    sort: Sort
    well: Well
    reports: PickerOptions[]
    report: ReportApi | null
}

export type PayloadColumns = {
    columns: string[]
}

export enum Actions {
    FETCH_DATA = "reports/table/fetchData/pending",
    FETCH_REPORTS = "reports/table/fetchReports/pending"
}

const initialState: TableState = {
    data: {
        headers: [],
        wells: [],
        total: 0,
        nonDynamicHeaders: []
    },
    isAssignToMe: false,
    contributedByMe: false,
    nonDynamicHeaders: [],
    isLoading: false,
    headers: [],
    columns: [],
    errors: [],
    columnInitial: true,
    count: 50,
    page: 1,
    sort: {
        column: "external_id",
        type: "desc",
    },
    well: {
        name: "",
        external_id: "",
        id: 0
    },
    reports: [],
    report: null
}

export const fetchData = createAsyncThunk(
    Actions.FETCH_DATA,
    async ({ skip = initialState.page - 1, take = initialState.count, sort = initialState.sort, isData = false, fields = [], isFieldData = [], values = [], user, uid, isAssignToMe = initialState.isAssignToMe, contributedByMe = initialState.contributedByMe, ...report }: ReportApiRequest) => {
        const response = (await axios.get<Response<WellApi>>(`/wells/reports/${report.id}?skip=${skip}&take=${take}&orderBy=${sort.column}&orderType=${sort.type}&isData=${isData}&fields=${_.join(fields)}&values=${_.join(values)}&isFieldData=${_.join(isFieldData)}&user=${user}&uid=${uid}&isAssignToMe=${isAssignToMe}&contributedByMe=${contributedByMe}`)).data
        return response.payload
    }
)

export const fetchReports = createAsyncThunk(
    Actions.FETCH_REPORTS,
    async () => {
        const response = await axios.get<Response<ReportApi[]>, AxiosResponse<Response<ReportApi[]>>>("/reports")
        const options: PickerOptions[] = response.data.payload!.map(report => {
            return {
                label: report.name,
                value: JSON.stringify(report)
            }
        })
        return options
    }
)

export const tableSlice = createSlice({
    name,
    initialState,
    reducers: {
        setColumns: (state: TableState, action: PayloadAction<PayloadColumns>) => {
            state.columns = action.payload.columns
        },
        setPage: (state: TableState, action: PayloadAction<number>) => {
            state.page = action.payload
        },
        setColumnInitialState: (state: TableState, action: PayloadAction<boolean>) => {
            state.columnInitial = action.payload
        },
        setCount: (state: TableState, action: PayloadAction<number>) => {
            state.count = action.payload
        },
        setSort: (state: TableState, action: PayloadAction<Sort>) => {
            state.sort = action.payload
        },
        setWell: (state: TableState, action: PayloadAction<Well>) => {
            state.well = action.payload
        },
        setReport: (state: TableState, action: PayloadAction<ReportApi>) => {
            state.report = action.payload
        },
        setIsAssigneToMe: (state: TableState, action: PayloadAction<boolean>) => {
            state.isAssignToMe = action.payload
        },
        setContributedByMe: (state: TableState, action: PayloadAction<boolean>) => {
            state.contributedByMe = action.payload
        },
        clearWell: (state: TableState) => {
            state.well = initialState.well
        },
        reset: (state: TableState) => {
            return {
                ...initialState, reports: state.reports
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchData.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(fetchData.fulfilled, (state, action) => {
                state.data = action.payload!
                state.headers = action.payload!.headers.map(header => ({
                    label: _.startCase(header),
                    key: header
                }))
                if (!state.columns.length) {
                    state.columns = action.payload!.headers.filter(
                        column => !column.includes("external_id") &&
                            !column.includes("agora_well") &&
                            !column.includes("reportId") &&
                            !column.includes("id") &&
                            !column.includes("tags")

                    )
                }
                state.nonDynamicHeaders = action.payload!.nonDynamicHeaders
                state.columnInitial = false
                state.isLoading = false
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.isLoading = false
                state.errors = [...state.errors, {
                    ...action.error,
                }]
            })
            .addCase(fetchReports.pending, (state, action) => {
                state.isLoading = true
            })
            .addCase(fetchReports.fulfilled, (state, action) => {
                state.isLoading = false
                state.reports = action.payload
            })
            .addCase(fetchReports.rejected, (state, action) => {
                state.isLoading = false
                state.errors = [...state.errors, {
                    ...action.error,
                }]
            })
    }
})

export const { clearWell, setColumns, setColumnInitialState, setPage, setCount, setSort, setWell, setReport, setIsAssigneToMe, setContributedByMe, reset } = tableSlice.actions

export default tableSlice.reducer