import { createAsyncThunk, createSlice, SerializedError } from "@reduxjs/toolkit"
import { ReportApi, Response, WellApi } from "types";
import { axios } from "utils/axios";
const name = "builder/tableQueries"

export type QueryTableState = {
    data: any[];
    isLoading: boolean;
    headers: string[];
    errors: SerializedError[];
}

export enum Actions {
    FETCH_QUERIES = "builder/query/fetchTableQuery/pending",
}

const initialState: QueryTableState = {
    data: [],
    isLoading: false,
    headers: [],
    errors: [],
}

export const fetchTableQuery = createAsyncThunk(
    Actions.FETCH_QUERIES,
    async (report: ReportApi) => {
        const response = (await axios.get<Response<WellApi>>(`/wells/reports/${report.id}`)).data
        return { data: response.payload!.wells, headers: response.payload!.headers };
    }
)

export const tableSlice = createSlice({
    name,
    initialState,
    reducers: {
        reset: () => {
            return {
                ...initialState
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTableQuery.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchTableQuery.fulfilled, (state, action) => {
                const { headers, data } = action.payload
                if (headers.length && data.length) {
                    state.headers = headers
                    state.data = data;
                }
                state.isLoading = false;
            })
            .addCase(fetchTableQuery.rejected, (state, action) => {
                state.errors = [...state.errors, {
                    ...action.error,
                }]
                state.isLoading = false;
            })
    }
})


export const { reset } = tableSlice.actions

export default tableSlice.reducer