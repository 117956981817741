import { FC, lazy, Suspense, useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import Layout from "components/layouts";
import Loading from "components/loading/app.loading";
import NotFound from "pages/notfound";
import { context } from "providers";

import pkg from "../../package.json";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import VersionRouter from "./versions.router";

const routes = {
	auth: [
		{
			path: "/",
			component: "login"
		},
		{
			path: "/reset-password",
			component: "reset-password",
		},
		{
			path: "/password-verification",
			component: "password-verification"
		},
		{
			path: "/otp-verification",
			component: "otp-verification"
		},
		{
			path: "/__/auth/handler",
			component: "redirect"
		}
	],
	app: [
		{
			path: "/",
			component: "reports"
		},
		{
			path: "/:reportName/:external_id",
			component: "reports"
		},
		{
			path: "/:reportName/:external_id/:noteId",
			component: "reports"
		},
		{
			path: "/builder",
			component: "builder"
		},
		{
			path: "/search-notes",
			component: "search-notes"
		}
	]
}


const AppRoutes: FC = () => {
	const { isLoggedIn, loading: loadingAuth, token } = useContext(context.auth);
	const { loading } = useContext(context.loading);
	const [client, setClient] = useState(new ApolloClient({
		uri: `${process.env.REACT_APP_WELLNOTES_API}/users`,
		cache: new InMemoryCache(),
	}))
	const [version, setVersion] = useState("")
	const [loadingVersion, setLoadingVersion] = useState(false);

	useEffect(() => {
		if (token) {
			setClient(new ApolloClient({
				uri: `${process.env.REACT_APP_WELLNOTES_API}/users`,
				cache: new InMemoryCache(),
				headers: {
					"Authorization": "Bearer " + token
				}
			}))
		}
	}, [token])

	useEffect(() => {
		setLoadingVersion(true)
		const execute = async () => {
			try {
				const v = (await getDoc(doc(getFirestore(), "versions", "actual"))).data() as { value: string }
				setVersion(v.value);
				setLoadingVersion(false)
			} catch (error) {
				setLoadingVersion(false)
			}
		}
		execute()
	}, [])

	if (loadingAuth) {
		return <Loading />
	}

	if (loading) {
		return <Loading />
	}

	if (loadingVersion) {
		return <Loading />
	}

	if (pkg.version !== version) {
		return <VersionRouter server={version} app={pkg.version} />
	}

	return (
		<ApolloProvider client={client}>
			<Routes>
				{isLoggedIn ?
					<Route path="/" element={<Layout.App />}>
						{
							routes.app.map(
								(route) => {
									const Page = lazy(
										() => import(`../pages/${route.component}`)
									)
									return <Route key={route.path} path={route.path} element={
										<Suspense fallback={<Loading />}>
											<Page />
										</Suspense>
									}

									/>
								}
							)
						}
					</Route>
					:
					<Route path="/" element={<Layout.Auth />}>
						{routes.auth.map(
							(route) => {
								const Page = lazy(
									() => import(`../pages/${route.component}`)
								)
								return <Route key={route.path} path={route.path} element={
									<Suspense fallback={<Loading />}>
										<Page />
									</Suspense>
								} />
							}
						)}
					</Route>
				}
				<Route path="*" element={<NotFound />} />
			</Routes >
		</ApolloProvider>
	);
};

export default AppRoutes;

/* {getAllowedRoutes([...roles, Roles.GUEST] as Roles[], links).map(
			(route) => {
			return (
			<Route exact={route.exact} key={route.name} path={route.path} component={lazy(
					() => import(`pages/${route.component}`)
				)}
			/>);
			}
		  )} */