import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import _ from "lodash";
import { ResponseSearchNotes, HeaderSearchNotes, Response, SearchNote, SearchNotesSort } from "types";
import { axios } from "utils/axios";

const name = "searchNotes/table";

export type TableState = {
    data: ResponseSearchNotes
    isLoading: boolean
    headers: HeaderSearchNotes[]
    nonDynamicHeaders: string[]
    columns: string[]
    columnInitial: boolean
    errors: SerializedError[]
    count: number
    page: number
    sort: SearchNotesSort
    note: SearchNote
    keyword: String | null
}

export enum Actions {
    FETCH_NOTES = "searchNotes/table/fetchNotes/pending",
}

const initialState: TableState = {
    data: {
        headers: [],
        notes: []
    },
    nonDynamicHeaders: [],
    isLoading: false,
    headers: [],
    columns: [],
    errors: [],
    columnInitial: true,
    count: 50,
    page: 1,
    sort: {
        column: "noteId",
        type: "desc",
    },
    note: {
        noteId: "",
        name: "",
        note: "",
        user: "",
        externalId: 0,
        reportName: "",
        createdAt: null
    },
    keyword: null
}

export const fetchNotes = createAsyncThunk(
    Actions.FETCH_NOTES,
    async (keyword: string) => {
        const response = (await axios.get<Response<ResponseSearchNotes>>(`notes/search?keyword=${keyword}`)).data;
        return response.payload
    }
)

export const tableSlice = createSlice({
    name,
    initialState,
    reducers: {
        setKeyword: (state: TableState, action: PayloadAction<String>) => {
            state.keyword = action.payload
        },
        setNote: (state: TableState, action: PayloadAction<SearchNote>) => {
            state.note = action.payload
        },
        setCount: (state: TableState, action: PayloadAction<number>) => {
            state.count = action.payload
        },
        setSort: (state: TableState, action: PayloadAction<SearchNotesSort>) => {
            state.sort = action.payload
        },
        refresh: (state: TableState) => {
            return {
                ...initialState, note: state.note
            }
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotes.pending, (state,) => {
                state.isLoading = true
            })
            .addCase(fetchNotes.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload!
                state.headers = action.payload!.headers.map(header => ({
                    label: _.startCase(header),
                    key: header
                }))
                state.columns = action.payload!.headers.filter(
                    column => !column.includes("noteId") &&
                        !column.includes("externalId")
                )

            })
            .addCase(fetchNotes.rejected, (state, action) => {
                state.isLoading = false
                state.errors = [...state.errors, {
                    ...action.error,
                }]
            })
    }
})

export const {
    setKeyword, setNote, setSort, setCount, refresh
} = tableSlice.actions;

export default tableSlice.reducer;