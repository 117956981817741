import { User } from "firebase/auth"

export type PropsFilterPicker = {
    name: string
}

export type PropsFilterPickerFooter = {
    fetchData: () => Promise<void>
    fetchFilters: (isSameFilter?: boolean, conditions?: string[]) => Promise<void>
}

export type SortType = "desc" | "asc" | undefined;

export enum CalculatedColumn {
    IS_ASSIGN_TO = "isAssignTo",
    HAS_PENDING_NOTES = "hasStatusPending",
    USER_CONTRIBUTION = "userContribution"
}

export type Note = {
    note: string;
    assignTo?: string | null;
    deadline?: Date | null;
}

export type ReportResponse = {
    results: any[];
    total: number,
    values?: string[]
}

export type Header = {
    key: string,
    label: string,
    // fixed: boolean,
    // width: number
}

export type Sort = {
    column: string
    type: SortType
}

export type Well = {
    name: string
    external_id: string
    id: number

}

export type PayloadInsertNote = {
    well: Well
    user: User
    files: string[]
    note: string,
    assignTo?: string | null;
    deadline?: Date | null;
    status: NoteStatus
}

export type PayloadUpdateNote = {
    id: number;
} & PayloadInsertNote

export type PayloadUpdateStatusNote = {
    id: number
    status: NoteStatus
}

export enum NoteStatus {
    RESOLVED = 'RESOLVED',
    PENDING = 'PENDING',
    NOT_APPLY = 'NOT_APPLY'
};

export type NoteApi = {
    id: number
    description: string
    active: boolean
    user: string
    createdAt: Date
    updatedAt: Date
    deletedAt: Date | null
    status: NoteStatus
    wellId: number
    deadline?: Date | null
    assignTo?: string | null
    attachments: AttachmentApi[]
}

export type RequestCreateNote = Omit<NoteApi, "active" | "createdAt" | "updatedAt" | "deletedAt"> | {
    attachments: string[];
}

export type ResponseCreateNote = Omit<NoteApi, "attachments">

export type AttachmentApi = {
    id: number
    url: string
    noteId: number
}

export type WellApi = {
    wells: Record<string, any>[],
    headers: string[]
    total: number
    nonDynamicHeaders: string[]
}

export type ReportApi = {
    id?: number
    name: string
    sql: string
    isExtracting?: boolean
}

export type ReportApiRequest = {
    isAssignToMe: boolean
    contributedByMe: boolean
    skip?: number
    take?: number
    sort?: Sort
    isData?: boolean
    fields?: string[]
    values?: string[]
    isFieldData?: boolean[]
    user: string
    uid: string
} & ReportApi

export type ResponseGetNoteByWell = NoteApi

export type FiltersApiRequest = {
    column: string
} & ReportApiRequest

export type FiltersApi = {
    data: any[],
    column: string
    values: string[]
}